.verify-user {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    align-items: center;
}

.verify-form {
    width: 400px;
    background: #f0f0f0;
    border-radius: 8px;
    padding: 25px;
    border: 1px solid #ffdada;
}


.input-legend {
    font-size: 1.1rem;
    text-transform: uppercase;
    padding: 10px 0PX;
    color: #a09d9d;
    font-weight: 600;
}

.input-box input {
    width: 100%;
    font-size: 1.2rem;
    padding: 8px;
    border-radius: 7px;
    border: none;
}

.input-submit {
    padding-top: 15px;
}

.email-name {
    padding: 13px;
    background: #e0e0e0;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email-name button {
    background: none;
    border: 1px solid rgb(24, 24, 24);
    font-size: 0.7rem;
    padding: 10px;
    color: rgb(24, 24, 24);
    border-radius: 8px;
}

.error span {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #ff9595;
}

.input-submit input {
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    border: 1px solid;
    cursor: pointer;
    border-radius: 2px;
    background: #ffdcdc;
}