.header-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    background: #fff1f1;
}

.header-stable {
    width: fit-content;
    line-height: 16px;
    padding: 10px 25px;
    display: inline;
    height: fit-content;
}

.main-logo {
    font-weight: 700;
}

.sub-logo {
    font-weight: 400;
    font-size: .7rem;
    letter-spacing: 3.5px;
    color: #de0000;

}

.navigation {
    padding: 10px;
    height: fit-content;
}

.navigation ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    gap:10px;
    justify-content: space-evenly;
}

.navigation ul li a {
    font-size: 0.9rem;
    text-decoration: none;
    color: #787878;
    font-weight: 500;
    letter-spacing: 2.9px;
    text-transform: uppercase;
}

.auth {
    text-align: right;
    padding: 10px 25px;
}

.auth-text {
    height: fit-content;
    font-size: 0.7rem;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.logout {
    height: fit-content;
    background: none;
}

.logout button {
    background: none;
    border: none;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 800;
}

.mobile-navigation {
    display: none;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgb(255, 241, 241);
    box-shadow: 0px -2px 18px 0px rgb(207 207 207);
}

.mobile-navigation ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    justify-content: space-around;
}

.mobile-navigation ul li {
    width: 100%;
    text-align: center;
    transition-timing-function: ease;
    
}


.mobile-navigation ul li a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #000000;
    display:inline-block;
    width: 100%;
    padding: 20px 10px;
 
}

.mobile-navigation ul li a:hover {
    background: #e8e8e8;
    transition-timing-function: ease;
   
}

.mobile-navigation ul li a.active {
    background: #ffcfda;
}

@media (max-width: 1200px) {
    .header-container {
        grid-template-columns: 1fr 3fr 1fr;
    }
}

@media (max-width: 625px) {
    .header-container {
        grid-template-columns: 1fr 1fr;
    }

    .navigation {
      display: none;
    }

    .mobile-navigation {
        display: block;
    }
  }
