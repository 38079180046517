.listing-container {
    margin: 25px 25px;
    padding-bottom: 55px;
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr;
}

.listing-item {
    position: relative;
}

.listing-item-img {
    width: 100%;
    height: 250px;
}

.listing-item-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 8px;
}

a {
    color: unset;
}

.listing-content {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background: #f8f5f5;
    width: 100%;
    border-bottom-left-radius: 8px;
    display: grid;
    grid-template-columns: 7fr 2fr;
    border-bottom-right-radius: 8px;
    justify-content: space-between;
}

.listing-name-roamhome {
    font-size: 0.8rem;
}

@media (max-width: 625px) { 
    .listing-container {
        grid-template-columns: 1fr;
    }

    .guest-allowed {
        text-align: right;
        font-size: 0.8rem;
    }
}