.container-heading {
    padding: 20px 25px;
}

.short-desc {
    padding: 20px 25px;
    margin: 0px 25px;
    border-radius: 7px;
    background: #ffe0e0;
    font-size: 1rem;
    width: fit-content;
    color: #645c5c;
    letter-spacing: 1px;

}

.upcoming-section {
    padding: 25px 25px;
}

.upcoming-header {
    text-transform: uppercase;
    font-size: 1rem;

}

.container-reservations {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 25px 0px;
}


.reservation-item {
    cursor: pointer;
    padding: 20px;
    background: #fff7f7;
    border-radius: 12px;
    border: 1px solid #efefef;
    margin-bottom: 20px;
}

.property-details {
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-gap: 15px;
    align-items: center;
}

.property-img {
    width: 120px;
    height: 60px;
}


.property-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.homename {
    font-size: 1.5rem;
}

.roamhome-name {
    font-size: 0.9rem;
}


.arriving-in {
    
    font-size: 0.8rem;
    font-weight: 700;
    color: #ff2a00;
}

.guest-name {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: capitalize;
}


.guest-items {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    grid-gap: 10px;
}


.checkin {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    border-radius: 12px;
    font-weight: 500;
    border: 1px solid #ffd2d2;
}

.arrow {
    font-size: 2rem;
}

.payout-on {
    font-size: 0.7rem;
}

.payout-amt {
    font-size: 1.1rem;
}

.show {
    display: none;
}

.no-upcoming-container {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
}

@media (max-width: 625px) {
    .reservation-item {
        padding: 20px;
        background: #fafafae8;
        background: #ffffffe8;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
    }

    .short-desc {
        padding: 8px 25px;
        font-size: 0.7rem;
        line-height: 17px;

    }

    .upcoming-header {
        font-size: 0.8rem;
    }

    .container-reservations {
        grid-template-columns: 1fr;
    }

    .property-details {
        justify-content: space-between;
        position: relative;
        display: flex;
    }

    .property-img {
        height: 50px;
        width: 80px;
        position: absolute;
        right: 0;
        top: 0px;
    }

    .homename {
        font-size: 0.8rem;
    }

    .roamhome-name {
        display: none;
    }

    .arriving-in {
        display: none;
    }

    .guest-name {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #707070
    }

    .guest-items {
        grid-template-columns: 1fr;
        grid-gap: 1px;
    }

    .guest-details {
        padding-top: 10px;
        font-size: 0.8rem;
    }

    .checkin-details {
        position: relative;
    }

    .show {
        position: absolute;
        right: 6px;
        bottom: 44px;
        background: none;
    }

    .show button {
        border-radius: 8px;
        line-height: 5px;
        border: none;
        font-size: 0.6rem;
        color: #908789;
        padding: 4px;
        background: #f3f3f3;
    }

    .checkin {
        padding: 5px 0px;
        font-size: 1.1rem;
        display: unset;
        background: unset;
        border-radius: unset;
        border: unset;
    }

    .arrow {
        font-size: unset;
    }

    .payout-details {

        padding-top: 15px;
        padding-bottom: 5px;
    }

}